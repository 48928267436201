import React from "react";
import BasicTemplate from "../../../templates/BasicTemplate";
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, EditorState } from "draft-js";
import styled from "styled-components";
import DetailHeader from "../../../molucules/DetailHeader";
import { GetArticleDetailResponse } from "../../../../types/models";
import { useGetApi } from "../../../../hooks/useGetApi";
import { useParams } from "react-router-dom";
import Container from "../../../atoms/Container";

const ArticleCardDetail: React.FC = (_) => {
  const { articleId } = useParams<ArticleParam>();
  const { data, loading } = useGetApi<GetArticleDetailResponse>(
    `/articles/${articleId}`
  );

  if (loading) {
    return <BasicTemplate />;
  }

  const { imageUri, body, title } = data!;

  const contentState = convertFromRaw(JSON.parse(body));
  const raw = EditorState.createWithContent(contentState);

  return (
    <BasicTemplate>
      <Container>
        <DetailHeader postImg={imageUri} title={title} />
        <EditorWrapper>
          <Editor
            readOnly={true}
            toolbarHidden
            editorState={raw as any}
            onChange={() => {}}
          />
        </EditorWrapper>
      </Container>
    </BasicTemplate>
  );
};

export default ArticleCardDetail;

const EditorWrapper = styled.div`
  padding: 0 16px 16px;
`;

interface ArticleParam {
  articleId: string;
}
