import React from "react";
import feature1 from "../../../assets/img/service1.jpg";
import feature2 from "../../../assets/img/career.jpg";
import feature3 from "../../../assets/img/road-01.jpg";
import styled from "styled-components";
import Container from "../../atoms/Container";
import Section from "./Section";
import { BACKGROUND_COLOR } from "../../../theme";
import Row from "../../atoms/Row";
import Feature from "../../molucules/Feature";
import image from "../../../assets/img/logo.png";
const AboutItems: React.FC = () => {
  return (
    <Section backgroundColor={BACKGROUND_COLOR}>
      <ProblemWrapper>
        <StyledRow>
          <div className="col-lg-8 text-center mx-auto">
            <h2 className="font-weight-bold">ネスグローバルとは？ </h2>
            <p className="mb-3 w-75 mx-auto lead">
              留学会社ってたくさんあるけど、相談をどこにすればいいかわからない
            </p>
          </div>
        </StyledRow>
        <div className="row justify-content-center">
          <div
            className="col-md-8"
            style={{
              textAlign: "center",
            }}
          >
            <img alt="" className="img-fluid mt-5" src={image} />
          </div>
        </div>
        <Feature
          title="手数料０円で留学手配"
          description="最も安く留学に行くためのご相談ができます"
          imageUri={feature1}
        />
        <Feature
          title="サービス開始から20年"
          description="2001年留学手配サービス開始から延べ１００００人以上の留学を手配しています!"
          imageUri={feature2}
          imagePosition="right"
        />
        <Feature
          title="お客様の要望を第一にヒアリング"
          description="留学スタイルはお客様によって様々です。お一人お一人に合ったプランをご案内します"
          imageUri={feature3}
        />
      </ProblemWrapper>
    </Section>
  );
};

export default AboutItems;

const ProblemWrapper = styled(Container)`
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  @media (min-width: 768px) {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
`;

const StyledRow = styled(Row)`
  justify-content: center;
`;
