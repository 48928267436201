import * as React from "react";
import feature1 from "../../../assets/img/feature1.jpg";
import feature2 from "../../../assets/img/feature2.png";
import feature3 from "../../../assets/img/feature3.jpg";

import styled from "styled-components";
import Container from "../../atoms/Container";
import Section from "./Section";
import { BACKGROUND_COLOR } from "../../../theme";
import Row from "../../atoms/Row";
import Col from "../../atoms/Col";
import Feature from "../../molucules/Feature";
const Features: React.FC = () => {
  return (
    <Section backgroundColor={BACKGROUND_COLOR}>
      <ProblemWrapper>
        <StyledRow>
          <StyledCol>
            <h2>
              新型コロナウイルスの状況下、留学情報をいち早くお届けします！
            </h2>
            <p>
              留学情報を取得したいけれども、どうやればいいかわからない。対面でできない相談をオンライン上で
            </p>
          </StyledCol>
        </StyledRow>
        <Feature
          title="現在と渡航できる国や都市のことを聞きたい！ビザの発給状況はどうなの？"
          description="会員登録していただくことで、随時各国の入国状況や、ビザの最新情報をご覧いただけます"
          imageUri={feature1}
        />
        <Feature
          title="留学情報を得る動画があればみたい"
          description="オンライン上で最新の情報をまとめた動画を毎日更新!"
          imageUri={feature2}
          imagePosition="right"
        />
        <Feature
          title="留学情報をより知りたいので個別相談をしたい！"
          description="オンライン上での留学カウンセリングが可能です！また、定期的に開催する留学説明会へ参加が可能です！"
          imageUri={feature3}
        />
      </ProblemWrapper>
    </Section>
  );
};

export default Features;

const ProblemWrapper = styled(Container)`
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  @media (min-width: 768px) {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
`;

const StyledRow = styled(Row)`
  padding-bottom: 3rem;
  justify-content: center;
`;

const StyledCol = styled(Col)`
  text-align: center;
  @media (min-width: 992px) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  h2 {
    font-weight: 600;
  }

  p {
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }
`;
