import * as React from "react";
import Section from "./Section";
import { BACKGROUND_LIGHT_BLUE_COLOR } from "../../../theme";
import Container from "../../atoms/Container";
import Row from "../../atoms/Row";
import Col from "../../atoms/Col";
import styled from "styled-components";
import au from "../../../assets/img/media/flag_au.gif";
import ca from "../../../assets/img/media/flag_ca.gif";
import fr from "../../../assets/img/media/flag_fr.gif";
import ir from "../../../assets/img/media/flag_ir.gif";
import ma from "../../../assets/img/media/flag_ma.gif";
import nz from "../../../assets/img/media/flag_nz.gif";
import uk from "../../../assets/img/media/flag_uk.gif";
import us from "../../../assets/img/media/flag_us.gif";

const Media: React.FC = (_) => {
  return (
    <Section backgroundColor={BACKGROUND_LIGHT_BLUE_COLOR}>
      <Container>
        <StyledRow>
          <StyledCol>
            <h2>対象国</h2>
          </StyledCol>
        </StyledRow>
        <Row>
          <CompanyLogo src={au} />
          <CompanyLogo src={ca} />
          <CompanyLogo src={fr} />
          <CompanyLogo src={ir} />
          <CompanyLogo src={ma} />
          <CompanyLogo src={nz} />
          <CompanyLogo src={uk} />
          <CompanyLogo src={us} />
        </Row>
      </Container>
    </Section>
  );
};

export default Media;

const CompanyLogoWrapper = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  @media (min-width: 768px) {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  img {
    max-width: 100%;
  }
`;

const CompanyLogo: React.FC<{ src: string }> = ({ src }) => {
  return (
    <CompanyLogoWrapper>
      <img src={src} alt="" />
    </CompanyLogoWrapper>
  );
};

const StyledRow = styled(Row)`
  padding-bottom: 3rem;
  justify-content: center;
`;

const StyledCol = styled(Col)`
  text-align: center;
  @media (min-width: 992px) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  h2 {
    font-weight: 400;
  }

  p {
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }
`;
