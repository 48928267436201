import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import AccountTemplate from "../../templates/AccountTemplate";
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import PrimaryButton from "../../atoms/PrimaryButton";
import styled from "styled-components";
import { LoginRequest, LoginResponse } from "../../../types/models";
import { postAsync } from "../../../modules/apiClient";
import { setToken } from "../../../modules/auth";

const Login: React.FC = _ => {
  const { register, handleSubmit, errors } = useForm<LoginRequest>();
  const history = useHistory();
  const [error, setError] = useState("");
  const onSubmit = handleSubmit(async ({ email, password }) => {
    try {
      const { status, data } = await postAsync<LoginResponse>("/login", {
        email,
        password
      });

      if (status === 200) {
        setToken(data.token!);
        // updateUser();
        history.push("/articles");
      }
    } catch (e: any) {
      const errorMessage = e.response.data?.error?.description;
      if (errorMessage) {
        setError(errorMessage);
      }
    }
  });

  return (
    <AccountTemplate title="ログイン">
      <form onSubmit={onSubmit}>
        <TextField
          fullWidth
          name="email"
          error={Boolean(errors.email)}
          inputRef={register({
            required: {
              value: true,
              message: "メールアドレスを入力してください"
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "メールアドレスの形式が違います"
            }
          })}
          helperText={errors?.email?.message}
          label="メールアドレス"
        />
        <TextField
          fullWidth
          label="パスワード"
          error={Boolean(errors.password)}
          inputRef={register({
            required: {
              value: true,
              message: "パスワードを入力してください"
            }
          })}
          helperText={errors?.password?.message}
          type="password"
          name="password"
        />
        <ButtonContainer>
          <PrimaryButton fullWidth type="submit">
            ログイン
          </PrimaryButton>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </ButtonContainer>
      </form>
    </AccountTemplate>
  );
};

export default Login;

const ButtonContainer = styled.div`
  min-width: 40px;
  min-height: 40px;
  padding: 10px 0px;
  font-size: 16px;
`;

const ErrorMessage = styled.p`
  margin-top: 16px;
  color: red;
`;
