import * as React from "react";
import image from "../../../assets/img/articles.png";
import { Link } from "react-router-dom";
const CTA: React.FC = () => {
  return (
    <section className="jumbotron bg-primary" id="header">
      <div className="container text-center text-lg-left">
        <div className="row align-items-center">
          <div className="col-md-12 col-lg-6 col-xl-5">
            <h1 className="font-weight-bold text-white">
              今すぐオンラインサロンへ参加
            </h1>
            <p className="mb-5 text-white font-weight-bolder">
              オンラインサロンへの参加のステップは簡単です。名前とメールアドレスを登録だけ！
            </p>
            <Link
              to="/signup?planId=6060312dcc344f00ade9b047edf14d96"
              className="btn btn-white btn-lg text-primary"
            >
              会員登録する
            </Link>
          </div>
          <div className="col-md-8 m-auto ml-lg-auto mr-lg-0 col-lg-6 pt-5 pt-lg-0">
            <img alt="" className="img-fluid" src={image} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTA;
