import React from "react";
import styled from "styled-components";
import Container from "../../atoms/Container";
import Section from "./Section";
import { BACKGROUND_LIGHT_BLUE_COLOR } from "../../../theme";
import Row from "../../atoms/Row";
import Col from "../../atoms/Col";
const Benefit: React.FC = () => {
  return (
    <Section backgroundColor={BACKGROUND_LIGHT_BLUE_COLOR}>
      <ProblemWrapper>
        <StyledRow>
          <StyledCol>
            <h2>ネスグローバルを利用する特典</h2>
          </StyledCol>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="bg-white p-5 rounded-lg">
                <i className="fa fa-2x fa-yen-sign text-white bg-primary p-3 rounded-lg mb-4"></i>
                <h5 className="font-weight-bold">留学に関わる手配料が0円</h5>
              </div>
              <svg
                className="fill-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 320"
              >
                <path
                  fill-opacity="1"
                  d="M0,32L30,58.7C60,85,120,139,180,154.7C240,171,300,149,360,128C420,107,480,85,540,69.3C600,53,660,43,720,53.3C780,64,840,96,900,122.7C960,149,1020,171,1080,165.3C1140,160,1200,128,1260,128C1320,128,1380,160,1410,176L1440,192L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"
                ></path>
              </svg>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bg-white p-5 rounded-lg mt-4">
                <i className="fa fa-2x fa-plane text-white bg-secondary p-3 rounded-lg mb-4"></i>
                <h5 className="font-weight-bold">
                  海外航空券・海外旅行保険の手配
                </h5>
              </div>
              <svg
                className="fill-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 320"
              >
                <path
                  fill-opacity="1"
                  d="M0,32L30,58.7C60,85,120,139,180,154.7C240,171,300,149,360,128C420,107,480,85,540,69.3C600,53,660,43,720,53.3C780,64,840,96,900,122.7C960,149,1020,171,1080,165.3C1140,160,1200,128,1260,128C1320,128,1380,160,1410,176L1440,192L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"
                ></path>
              </svg>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bg-white p-5 rounded-lg mt-5">
                <i className="fa fa-2x fa-comment-dots text-white bg-tertiary p-3 rounded-lg mb-4"></i>
                <h5 className="font-weight-bold">
                  出発前・出発後の英会話レッスンが無料
                </h5>
              </div>
              <svg
                className="fill-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 320"
              >
                <path
                  fill-opacity="1"
                  d="M0,32L30,58.7C60,85,120,139,180,154.7C240,171,300,149,360,128C420,107,480,85,540,69.3C600,53,660,43,720,53.3C780,64,840,96,900,122.7C960,149,1020,171,1080,165.3C1140,160,1200,128,1260,128C1320,128,1380,160,1410,176L1440,192L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"
                ></path>
              </svg>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bg-white p-5 rounded-lg">
                <i className="fa fa-2x fa-user text-white bg-primary p-3 rounded-lg mb-4"></i>
                <h5 className="font-weight-bold">
                  留学中の語学向上ミッションサポート
                </h5>
              </div>
              <svg
                className="fill-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 320"
              >
                <path
                  fill-opacity="1"
                  d="M0,32L30,58.7C60,85,120,139,180,154.7C240,171,300,149,360,128C420,107,480,85,540,69.3C600,53,660,43,720,53.3C780,64,840,96,900,122.7C960,149,1020,171,1080,165.3C1140,160,1200,128,1260,128C1320,128,1380,160,1410,176L1440,192L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"
                ></path>
              </svg>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bg-white p-5 rounded-lg mt-4">
                <i className="fa fa-2x fa-building text-white bg-secondary p-3 rounded-lg mb-4"></i>
                <h5 className="font-weight-bold">
                  帰国後のキャリアサポート付き
                </h5>
              </div>
              <svg
                className="fill-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 320"
              >
                <path
                  fill-opacity="1"
                  d="M0,32L30,58.7C60,85,120,139,180,154.7C240,171,300,149,360,128C420,107,480,85,540,69.3C600,53,660,43,720,53.3C780,64,840,96,900,122.7C960,149,1020,171,1080,165.3C1140,160,1200,128,1260,128C1320,128,1380,160,1410,176L1440,192L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"
                ></path>
              </svg>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bg-white p-5 rounded-lg mt-5">
                <i className="fa fa-2x fa-users text-white bg-tertiary p-3 rounded-lg mb-4"></i>
                <h5 className="font-weight-bold">国際交流コミュニティの参加</h5>
              </div>
              <svg
                className="fill-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 320"
              >
                <path
                  fill-opacity="1"
                  d="M0,32L30,58.7C60,85,120,139,180,154.7C240,171,300,149,360,128C420,107,480,85,540,69.3C600,53,660,43,720,53.3C780,64,840,96,900,122.7C960,149,1020,171,1080,165.3C1140,160,1200,128,1260,128C1320,128,1380,160,1410,176L1440,192L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"
                ></path>
              </svg>
            </div>
          </div>
        </StyledRow>
      </ProblemWrapper>
    </Section>
  );
};

export default Benefit;

const ProblemWrapper = styled(Container)`
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  @media (min-width: 768px) {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
`;

const StyledRow = styled(Row)`
  padding-bottom: 3rem;
  justify-content: center;
`;

const StyledCol = styled(Col)`
  text-align: center;
  @media (min-width: 992px) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  h2 {
    font-weight: 600;
  }

  p {
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }
`;
